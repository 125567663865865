exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-confirmation-jsx": () => import("./../../../src/pages/confirmation.jsx" /* webpackChunkName: "component---src-pages-confirmation-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-policies-jsx": () => import("./../../../src/pages/legal-policies.jsx" /* webpackChunkName: "component---src-pages-legal-policies-jsx" */),
  "component---src-pages-services-wordpress-jsx": () => import("./../../../src/pages/services/wordpress.jsx" /* webpackChunkName: "component---src-pages-services-wordpress-jsx" */),
  "component---src-pages-work-jsx": () => import("./../../../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */)
}

